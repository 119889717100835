import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import { getCurrentUser } from "vuefire";

function lazyView(view: string) {
  return () => import(`../pages/${view}.vue`);
}
const StorePage = () => import("../pages/StorePage.vue");
const StoreDetailPage = () => import("../pages/StoreDetailPage.vue");

const DashboardPage = () => import("../pages/DashboardPage.vue");

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Intro",
    component: DashboardPage,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "home-redirect",
        redirect: (_to) => ({ name: "HomePage" }),
      },
      {
        path: "dashboard",
        name: "HomePage",
        component: lazyView("HomePage"),
      },

      {
        path: "help",
        name: "HelPage",
        component: lazyView("HelpPage"),
      },
      {
        path: "get-connected",
        name: "OnboardingPage",
        component: lazyView("OnboardingPage"),
      },
      {
        path: "/payments",
        name: "PaymentsPage",
        component: lazyView("PaymentsPage"),
      },
      {
        path: "/stores",
        name: "StoresPage",
        component: lazyView("StoresPage"),
      },
      {
        path: "/stores/:store/settings",
        name: "UserStoreSettingsPage",
        component: lazyView("SettingsPage"),
      },
      {
        path: "/merchants/:merchant/payouts",
        name: "PayoutsPage",
        component: lazyView("MerchantPayoutPage"),
      },

      {
        path: "/merchants/:merchant/new-order",
        name: "NewOrder",
        component: lazyView("NewOrderPage"),
      },
      {
        path: "/merchants/:merchant/orders",
        name: "OrderPage",
        component: lazyView("OrderPage"),
      },
      {
        path: "/merchants/:merchant/stores/:store",
        name: "StorePage",
        component: StorePage,
        children: [
          {
            path: "",
            name: "store-redirect",
            redirect: (_to) => ({ name: "StoreDetailPage" }),
          },

          {
            path: "payments/:date",
            name: "StoreDetailPage",
            component: StoreDetailPage,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "LoginPage",
    component: lazyView("LoginWithEmailPage"),
  },
  {
    path: "/t",
    name: "T",
    component: lazyView("BalancesPage"),
  },
  {
    path: "/tpg",
    name: "TPG",
    component: lazyView("AdminPage"),
  },
  {
    path: "/checkout",
    name: "CheckoutPage",
    component: lazyView("CheckoutPage"),
  },

  {
    path: "/email-signin",
    name: "EmailSignPage",
    component: lazyView("EmailSigninPage"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  // routes with `meta: { requiresAuth: true }` will check for
  // the users, others won't
  if (to.meta.requiresAuth) {
    const currentUser = await getCurrentUser();
    // if the user is not logged in, redirect to the login page
    if (!currentUser) {
      return {
        path: "/login",
        query: {
          // we keep the current path in the query so we can
          // redirect to it after login with
          // `router.push(route.query.redirect || '/')`
          redirect: to.fullPath,
        },
      };
    }
  }
});

export default router;
